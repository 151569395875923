import { Modal } from "bootstrap";
import {callbackFn} from "@tinymce/tinymce-vue/lib/es2015/main/ts/ScriptLoader";

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const modalHideEvent = (modalEl: HTMLElement | null, action): void => {
  if (!modalEl) {
    return;
  }

  const myModal = document.getElementById(modalEl.id)
    myModal?.addEventListener('hidden.bs.modal', function (event) {
      action ? action() : null
    })



};

export { removeModalBackdrop, hideModal, modalHideEvent};
